import React from 'react'
import styles from './how_it_works.module.scss'
import CardIcons from './../CardIcons'
import Grid from '@material-ui/core/Grid';

const HowItWorks = () => {
	return(
		<div className={styles.container}>
			<h2> ¿Cómo funciona? </h2>
			<p> El proceso es fácil y flexible, puedes conocernos sin importar donde estés. </p>
			<div className={styles.containerCards}>
				{
					dataHow.map((el,idx)=> (
						<Grid item xs={12} md={4}>
						<div className={styles.miniCards}>
							<CardIcons title={el.title} image={el.image} description={el.description} />
						</div>
						</Grid>
					))
				}
			</div>
		</div>
	)
}

const dataHow = [
	{
		title: 'Explora Cowork',
		image: 'group_steps.svg',
		description: 'Conoce y explora nuestra plataforma, en donde podrás conocer nuestros espacios, comparar y leer sus beneficios.'
	},
	{
		title: 'Elige un Espacio',
		image: 'stats_steps.svg',
		description: 'Una vez elijas el espacio que mejor se acomoda a tus necesidades, estaremos ansiosos esperandote.'
	},
	{
		title: 'Expande tu empresa',
		image: 'buy_steps.svg',
		description: 'En Cowork entre todos, somo flexibles, permitimos la personalización 100% de las oficinas. Inspiramos e impulsamos nuevos talentos empresariales.'
	}
]

export default HowItWorks
