import React from 'react'
import styles from './featured_space.module.scss'
import SpaceBoxFeatured from './../SpaceBoxFeatured'
import { Button } from '@material-ui/core';

const FeaturedSpaces = () => {

	return(
		<div className={styles.container}>
			<h2> Espacio Destacados </h2>
			<div className={styles.boxContainer}>
				{
					spaces.map((el,idx)=>(
						<div key={idx} className={styles.minContainer}>
							<SpaceBoxFeatured image={el.image} title={el.title} price={el.price} capacity={el.capacity} meters={el.meters} sede={el.sedeOffice}/>
						</div>
					))
				}
			</div>
			<div className={styles.buttonFeaturedSpaceContainer}>
				<Button variant="outlined" className={styles.buttonFeaturedSpace}>
					Explorar
				</Button>
			</div>
		</div>
	)
}

const spaces = [
	{
		image: require('./../../images/conference01.jpg'),
		title: 'Sala de Juntas Prado',
		price: '200.000',
		capacity: '8',
		meters: '1200',
		sedeOffice: 'Sede Prado'
	},
	{
		image: require('./../../images/conference02.jpg'),
		title: 'Puerta de Oro: Sala de Juntas',
		price: '400.000',
		capacity: '35',
		meters: '360',
		sedeOffice: 'Sede 84'
	},
	{
		image: require('./../../images/conference03.jpg'),
		title: 'Sala de Juntas Viejo Prado',
		price: '250.000',
		capacity: '35',
		meters: '2200',
		sedeOffice: 'Sede Prado'
	}
]

export default FeaturedSpaces
