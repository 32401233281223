import React,{useState,useEffect} from 'react'
import styles from './sede.module.scss'
import Layout from './../../components/Layout'
import SEO from './../../components/seo'
import MainSectionSede from './../../components/MainSectionSede'
import SpaceSection from './../../components/SpaceSection'
import HowItWorks from './../../components/HowItWorks'
import TestimoniosSection from './../../components/TestimoniosSection'
import ServiceSection from './../../components/ServiceSection'
import FeaturedSpaces from './../../components/FeaturedSpaces'
import ScheduleSection from './../../components/ScheduleSection'
import PartnerSection from './../../components/PartnerSection'
import FreeTour from './../../components/FreeTour'
import config from './../../config'
import Lottie from 'react-lottie';
import {navigate} from "gatsby"
import {useSelector, useDispatch} from 'react-redux'
import MapView from './../../components/MapView'
import axios from 'axios'
import Grid from '@material-ui/core/Grid';

const Sede = (props) => {
	const dispatch = useDispatch()
	const [headQuarterData, setHeadQuarterData] = useState()
	const handleFilterSpace = (name) => {
		dispatch({type: 'filter/SET_ACTUAL_TYPE_OFFICE_AND_HEAD_QUARTER', data: {typeOffice: name, sede: headQuarterData.name}})
		navigate('/filter')
	}

	const getSpaceOffice = async() => {
		try{
			const response = await axios(`${config.server}/getHeadQuarter/${props.pageContext.id}`,{
				method: 'GET',
				headers: {
					'Content-Type': 'application/json'
				}
			})
			if(response.data.feature_image == null){
				response.data.feature_image = 'sede_placeholder.png'
			}

			let newArr = [];
			response.data.office_type.map((el,idx)=>{
				if(el.feature_image == null){
					el.feature_image = 'tipo_placeholder.png'
				}
				newArr.push(el);
				if(idx == response.data.office_type.length-1){
					setHeadQuarterData(response.data)
				}
			})

			//setHeadQuarterData(response.data)
		}catch(e){
			console.log(e)
		}
	}

	useEffect(()=>{
		getSpaceOffice()
	},[])

	return(
		<div>
				{
					typeof headQuarterData != 'undefined' ? (
						<Layout>
							<SEO title={headQuarterData.name} />
							<MainSectionSede image={headQuarterData.feature_image} title={headQuarterData.name} spaces={headQuarterData.office_type}/>
							<SpaceSection uid={false} spaces={headQuarterData.office_type} handleParentSpace={handleFilterSpace}/>
							<HowItWorks/>
							<TestimoniosSection/>
							<ServiceSection/>
							{/*<FeaturedSpaces/>*/}
							{
								props.pageContext.events.length > 0 ? (
									<ScheduleSection schedules={props.pageContext.events}/>
								):(
									null
								)
							}
							<PartnerSection/>
							<div className={styles.mapContainer}>
								<h2>Ubicación</h2>
								<Grid item xs={12}>
								<MapView latitude={headQuarterData.address.lat} longitude={headQuarterData.address.lon}/>
								</Grid>
							</div>
							<FreeTour/>
						</Layout>
					):(
						null
					)
				}
		</div>

	)
}


const schedules = [
	{
		date: "04 Abril",
		title: "Pitch & Cerva",
		type: "Taller",
		color: "#16DC6A",
	},
	{
		date: "10 Abril",
		title: "Pitch & Cerva",
		type: "Conferencia",
		color: "#CD0D14",
	},
	{
		date: "01 Julio",
		title: "Pitch & Cerva",
		type: "Capacitacion",
		color: "#AFB2C4",
	},
	{
		date: "05 Julio",
		title: "Mini Curso de Oratoria",
		type: "Conferencia",
		color: "#AFB2C4",
	},
	{
		date: "08 Septiembre",
		title: "Mini Curso de Oratoria",
		type: "Dia del Padre",
		color: "#AFB2C4",
	},
	{
		date: "02 Noviembre",
		title: "Mini Curso de Oratoria",
		type: "Evento",
		color: "#AFB2C4",
	}
]


const spaces = [
	{
		title: 'Espacio Compartido',
		image: require("./../../images/espacio_conmpartido-min.jpg")
	},
	{
		title: 'Oficina Privada',
		image: require("./../../images/oficina_privada-min.jpg")
	},
	{
		title: 'Sala de Juntas',
		image: require("./../../images/sala_de_juntas-min.jpg")
	},
	{
		title: 'Sala Capacitaciones',
		image: require("./../../images/sala_capacitaciones.min.png")
	},
	{
		title: 'Sala de Reuniones',
		image: require("./../../images/sala_de_reuniones.min.png")
	},
	{
		title: 'Oficina Virtual',
		image: require("./../../images/oficina_virtual-min.jpg")
	}
]


export default Sede
