import React from 'react'
import styles from './card_icons.module.scss'
import { Card, CardContent } from '@material-ui/core';

const CardIcons = ({ title, description, image }) => {
	return(
		<Card className={styles.card}>
			<CardContent className={styles.flexContainer}>
				<img src={require(`./../../images/${image}`)}/>
				<h3> {title} </h3>
				<p> {description} </p>
			</CardContent>
		</Card>
	)
}

export default CardIcons
