import React, {useState} from 'react'
import styles from './main_section_sede.module.scss'
import { TextField, MenuItem, Button, SnackbarContent, Snackbar} from '@material-ui/core';
import { createMuiTheme } from "@material-ui/core/styles";
import {IoIosWarning} from "react-icons/io";
import { ThemeProvider } from "@material-ui/styles";
import {useSelector, useDispatch} from 'react-redux'
import {navigate} from 'gatsby'
import config from './../../config'
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(theme => ({
	root: {
	  flexGrow: 1,
	},
	paper: {
	  padding: theme.spacing(2),
	  textAlign: 'center',
	  color: theme.palette.text.secondary,
	},
  }));

const MainSectionSede = ({title, image, spaces}) => {
	const classes = useStyles();
	const dispatch = useDispatch()
	console.log(image)
	console.log(`${config.assets}/${image}`)
	//const img = require(`./../../images/${image}`)
	const [filter, setFilter] = useState({
		person: '',
		type: '',
		sede: title
	});
	const [openSnack,setOpenSnack] = useState(false)

	const handleChange = name => event => {
		setFilter({ ...filter, [name]: event.target.value });
	};

	const SnackBarWrapper = ({message}) => {
		return(
			<Snackbar
				anchorOrigin={{
				  vertical: 'bottom',
				  horizontal: 'center',
				}}
				style={{position:'absolute'}}
				open={openSnack}
				autoHideDuration={6000}
				onClose={()=>setOpenSnack(!openSnack)}
			  >
				<SnackbarContent
					style={{backgroundColor: '#d32f2f'}}
					  message={
						<span className={styles.errorMessage}>
							{
								<IoIosWarning size={20} className={styles.iconError}/>
							}
							<span>{message}</span>
						</span>
					  }
					  onClose={()=>setOpenSnack(!openSnack)}
					  variant="error"
				/>
			</Snackbar>
		)
	}

	const buttonFormSend = () => {
		if( (/^\s*$/.test(filter.person)) || (/^\s*$/.test(filter.type)) ){
			setOpenSnack(true)
		}else{
			dispatch({type: 'filter/SET_ACTUAL_FILTER_HEADQUARTER_AND_PERSON_AND_TYPE_OFFICE', data: filter})
			navigate('/filter')
		}
	}

	return(
		<div className={styles.container} style={{backgroundImage: `linear-gradient(
			rgba(0, 0, 0, 0.4),
			rgba(0, 0, 0, 0.4)
		  ), url(${config.assets}/${image})`}}>
			<div className={styles.dataContainer}>
				<SnackBarWrapper message='Faltan datos por llenar'/>
				<h1> {title} </h1>
				<p className={styles.subtitle}> Busca la mejor opcion entre nuestros espacios. </p>
				
				<div className={styles.mainForm}>
				<Grid container spacing={3}>
					
					<Grid item md={5} xs ={12}>
					<ThemeProvider theme={theme}>
						<TextField
							id="cantidadPersonas"
							select
							value={filter.person}
							onChange={handleChange('person')}
							label="Cantidad de personas"
							className={styles.textField}
							SelectProps={{
								MenuProps: {
								  className: styles.menu,
								},
							}}
							margin="normal"
							variant="filled">
								<MenuItem value="= 1">1</MenuItem>
								<MenuItem value="= 2">2</MenuItem>
								<MenuItem value="= 3">3</MenuItem>
								<MenuItem value="= 4">4</MenuItem>
								<MenuItem value=">= +5">+5</MenuItem>
						</TextField>
					</ThemeProvider> 
					
					
					</Grid>
					<Grid item md={5} xs ={12}>
						<ThemeProvider theme={theme}>
							<TextField
								id="elegirSede"
								select
								value={filter.type}
								onChange={handleChange('type')}
								label="Tipo de espacio"
								className={styles.textField}
								SelectProps={{
									MenuProps: {
									className: styles.menu,
									},
								}}
								margin="normal"
								variant="filled">
									{
										spaces.map((el,idx)=>(
											<MenuItem key={idx} value={el.name}>{el.name}</MenuItem>
										))
									}
							</TextField>
						</ThemeProvider> 
					</Grid>
					<Grid item md={2} xs ={12}>
						<Button variant="contained" onClick={buttonFormSend} className={styles.buttonFormSend}>
							Buscar
						</Button> 
					</Grid>
					
				</Grid>
					{/* <ThemeProvider theme={theme}>
						<TextField
							id="cantidadPersonas"
							select
							value={filter.person}
							onChange={handleChange('person')}
							label="Cantidad de personas"
							className={styles.textField}
							SelectProps={{
								MenuProps: {
								  className: styles.menu,
								},
							}}
							margin="normal"
							variant="filled">
								<MenuItem value="= 1">1</MenuItem>
								<MenuItem value="= 2">2</MenuItem>
								<MenuItem value="= 3">3</MenuItem>
								<MenuItem value="= 4">4</MenuItem>
								<MenuItem value=">= +5">+5</MenuItem>
						</TextField>
					</ThemeProvider> */}
					{/* <ThemeProvider theme={theme}>
						<TextField
							id="elegirSede"
							select
							value={filter.type}
							onChange={handleChange('type')}
							label="Tipo de espacio"
							className={styles.textField}
							SelectProps={{
								MenuProps: {
								  className: styles.menu,
								},
							}}
							margin="normal"
							variant="filled">
								{
									spaces.map((el,idx)=>(
										<MenuItem key={idx} value={el.name}>{el.name}</MenuItem>
									))
								}
						</TextField>
					</ThemeProvider> */}
					{/* <Button variant="contained" onClick={buttonFormSend} className={styles.buttonFormSend}>
						Buscar
					</Button> */}
				</div>
			</div>
		</div>
	)
}


const theme = createMuiTheme({
	overrides: {
      MuiFilledInput: {
          root: {
              position: 'relative',
			  color:'#23263E',
			  background: '#FFF',
          },
      },
      MuiFormLabel: {
          root: {
              '&$focused': {
                  color: 'rgba(0, 0, 0, 0.54)'
              }
          }
      }},
	  props: {
		  MuiFilledInput: {
	    	  disableUnderline: true
	  	  }
	  }
});

export default MainSectionSede
